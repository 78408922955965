@media screen and (min-width:1100px){.oview{margin:12rem 0 -5rem 0;}}
@media screen and (min-width:1475px){figure.effect{height:40rem;}
.oview{margin:17.5rem 0 -5rem 0;}}
@media screen and (min-width:1700px){.oview{margin:25rem 0 -5rem 0;}}
@media screen and (min-width:2000px){figure.effect{height:45rem;}
.oview{margin:27rem 0 -5rem 0;}}
@media screen and (min-width:2250px){figure.effect{height:50rem;}
.oview{margin:30rem 0 -5rem 0;}}
@media screen and (max-width:880px){figure.effect{height:30rem;}
figure.effect figcaption{font-size:3.8rem;width:70%;}
.oview{font-size:2.4rem;margin-top:8.5%;}
}
@media screen and (max-width:840px){#profilebg{height:32rem;}
#dp{width:17rem;height:17rem;}
#info{width:33rem;height:11rem;margin-left:0.9rem;}
#info>h1{font-size:2.3rem;margin-left:3rem;}
#info>span{font-size:1.7rem;margin-left:3rem;}
#l33t{width:6rem;height:auto;}
.midnav{height:4.3rem;font-size:1.5rem;letter-spacing:0.8rem;}
.midp{width:13.2rem;padding:0.82rem 0.9rem;}
.dropdown a{padding:0.82rem 0 0.82rem 0.9rem;}
figure.effect{height:30rem;}
figure.effect figcaption{font-size:3.8rem;width:70%;}
.oview{font-size:2.4rem;margin-top:8%;}
#contactForm, .alert{font-size:2.7rem;}
#submit{margin:auto auto 33rem 7rem;}
#introtxt{font-size:2.7rem;}
.bottomnav{margin:auto 2.5%;}
}
@media screen and (max-width:800px){#profilebg{height:30rem;}
#h5{font-size:1.25rem;}
#dp{width:15rem;height:15rem;}
#info{width:32rem;height:10rem;margin:14.5rem auto 3rem 1rem;}
#info>h1{font-size:2rem;}
#l33t{width:5rem;height:auto;}
figure.effect{height:27rem;margin:4.5rem 0;}
figure.effect figcaption{font-size:3.7rem;width:70%;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:4rem;}
.oview{font-size:2.3rem;margin-top:4.5%;}
#contactForm, .alert{font-size:2.3rem;}
#submit{margin:auto auto 30rem 7rem;}
#introtxt{font-size:2.3rem;}
.blink{width:10px;}
.bottomnav{margin:auto 2%;}
}
@media screen and (max-width:700px){.top{height:2.6rem;font-size:1.8rem;}
.top>p{font-size:1.8rem;}
#h5{font-size:1.3rem;}
#h5>.blink{width:7px;}
#profilebg{height:25rem;}
#dp{width:14rem;height:14rem;margin:9rem -3rem 2rem 2rem;}
#info{width:27rem;height:9rem;margin:11.5rem auto 3rem 1.3rem;}
#info>h1{font-size:1.8rem;margin-left:2.5rem;}
#info>span{font-size:1.6rem;margin-left:2.5rem;}
#l33t{width:4rem;margin-left:22.1rem;}
.midnav{height:3.8rem;font-size:1.35rem;letter-spacing:0.6rem;}
.midp{width:11.2rem;padding:0.67rem 0.8rem;}
.dropdown a{padding:0.67rem 0 0.67rem 0.8rem;}
#fleft{margin-left:7rem;}
#fright{margin-right:7rem;}
figure.effect{height:25rem;margin:4rem 0;}
figure.effect figcaption{font-size:3.1rem;width:70%;}
.oview{font-size:2rem;margin-top:2.5%;}
#contactForm, .alert{font-size:2rem;}
#submit{margin:auto auto 27rem 7rem;}
#introtxt{font-size:2rem;}
.bottomnav{font-size:2rem;margin:auto 2%;}
.instagram{width:2rem;}
.refind{width:2rem;}
.keybase{width:2.3rem;}
}
@media screen and (max-width:600px){#loader{width:100px;height:100px;}
#profilebg{height:20rem;}
#dp{width:11rem;height:11rem;margin:7rem -3rem 2rem 2rem;}
#info{width:20rem;height:7rem;margin:9rem auto 3rem 1.7rem;}
#info>h1{font-size:1.3rem;margin-left:2rem;margin-bottom:0rem;}
#info>span{font-size:1rem;margin-left:2rem;}
#l33t{width:2.7rem;margin-left:16.5rem;}
.midnav{height:3.6rem;font-size:1.2rem;letter-spacing:0.45rem;}
.midp{width:10.2rem;padding:0.7rem 1rem;border-bottom:5px solid #019100;}
.dropdown a{padding:0.7rem 0 0.7rem 0.55rem;border-bottom:5px solid #911000;}
figure.effect{height:23rem;}
figure.effect figcaption{font-size:3.2rem;width:70%;padding:0%;margin:-39% 0 0 0;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:2.5rem;}
.oview{font-size:2rem;}
#contactForm, .alert{font-size:1.8rem;}
.inputs{width:90%;margin-left:2rem;}
#submit{width:10%;margin:auto auto 23rem 7rem;}
#introtxt{font-size:1.8rem;}
.blink{width:8px;}
.bottomnav{font-size:1.9rem;margin:auto 1.5%;}
.instagram{width:1.9rem;}
.refind{width:1.9rem;}
.keybase{width:2.2rem;}
}
@media screen and (max-width:500px){#loader{width:95px;height:95px;}
.top{height:2rem;font-size:1.2rem;}
.top>p{font-size:1.2rem;}
#h5{font-size:1rem;}
#h5>.blink{width:5.5px;}
#profilebg{height:15rem;}
#dp{width:8rem;height:8rem;margin:5rem -3rem 2rem 2rem;}
#info{width:15rem;height:5.5rem;margin:6.25rem auto 3rem 1.9rem;}
#info>h1{font-size:1rem;margin-bottom:-0.3rem;}
#info>span{font-size:0.8rem;}
#l33t{width:2rem;margin-left:12.5rem;margin-top:-0.3rem;}
.midnav{height:3.4rem;font-size:1.15rem;letter-spacing:0.36rem;}
.midp{width:9.2rem;padding:0.67rem 0.9rem;border-bottom:4px solid #019100;}
.dropdown a{padding:0.67rem 0 0.67rem 0.5rem;border-bottom:4px solid #911000;}
#fleft{margin-left:5rem;}
#fright{margin-right:5rem;}
figure.effect{height:21rem;}
figure.effect figcaption{font-size:2.5rem;width:70%;margin:-42% 0 0 0;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:1.5rem;}
.oview{font-size:1.7rem;}
#contactForm, .alert{font-size:1.7rem;}
#introtxt{font-size:1.7rem;}
.bottomnav{font-size:1.8rem;margin:auto 1.3%;}
.instagram{width:1.8rem;}
.refind{width:1.8rem;}
.keybase{width:2.1rem;}
}
@media screen and (max-width:450px){.inputs{width:95%;margin-left:1rem;}
#submit{width:5%;margin:auto auto 23rem 3rem;}
figure.effect figcaption{margin:-47% 0 0 0;}
}
@media screen and (max-width:400px){
figure.effect figcaption{margin:-57.7% 0 0 0;}
}
@media screen and (max-width:370px){#loader{width:87px;height:87px;}
.top{height:1.5rem;font-size:1rem;}
.top>p{font-size:1rem;}
#h5{font-size:0.9rem;}
#h5>.blink{width:4px;}
#profilebg{height:12rem;}
#dp{width:6.5rem;height:6.5rem;margin:4.5rem -3rem 2rem 1rem;}
#info{width:12rem;height:4.2rem;margin:5.63rem auto 3rem 2.24rem;}
#info>h1{font-size:0.85rem;margin-left:1.2rem;margin-bottom:-0.5rem;}
#info>span{font-size:0.75rem;margin-left:1.2rem;}
#l33t{margin:-0.3rem 2rem 2rem 9.5rem;}
.midnav{height:2.7rem;font-size:0.85rem;letter-spacing:0.28rem;}
.midp{width:7.2rem;padding:0.6rem 0.9rem;border-bottom:3px solid #019100;}
.dropdown a{padding:0.6rem 0 0.6rem 0.3rem;border-bottom:3px solid #911000;}
#fleft{margin-left:3.5rem;}
#fright{margin-right:3.5rem;}
figure.effect{height:18rem;}
figure.effect figcaption{font-size:2rem;width:70%;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:0.9rem;}
.oview{font-size:1.5rem;font-weight:600;line-height: 100%;}
#contactForm, .alert{font-size:1.3rem;}
.inputs{width:95%;margin-left:0.5rem;}
#submit{width:5%;margin:auto auto 18rem 3rem;}
#introtxt{font-size:1.3rem;}
.blink{width:6px;}
.bottomnav{font-size:1.4rem;}
.instagram{width:1.4rem;}
.refind{width:1.4rem;}
.keybase{width:1.7rem;}
}		
@media screen and (max-width:300px){figure.effect figcaption{font-size:1.8rem;width:70%;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:0.83rem;}
.oview{font-size:1.35rem;}
}
@media screen and (max-width:262px){#fleft{margin-left:2rem;}
#fright{margin-right:2rem;}
figure.effect figcaption{font-size:1.5rem;width:70%;margin-top:-70%;}
figure.effect figcaption::before, figure.effect figcaption::after{padding-top:0.8rem;}
.oview{font-size:1.2rem;}
#contactForm, .alert{font-size:1.1rem;}
#introtxt{font-size:1.1rem;}
.bottomnav{font-size:1.2rem;}
.instagram{width:1.2rem;}
.refind{width:1.2rem;}
.keybase{width:1.5rem;}
}
@media screen and (max-width:210px){.top{height:1.2rem;font-size:0.8rem;}
.top>p{font-size:0.8rem;}
#profilebg{height:8rem;}
#dp{width:4.5rem;height:4.5rem;margin:2.5rem -3rem 2rem 1rem;}
#info{width:8rem;height:3rem;margin:3.18rem auto 3rem 2.4rem;}
#info>h1{font-size:0.6rem;margin:0.3rem auto 0rem 1rem;}
#profile>#info>span{font-size:0.5rem;margin:1rem;margin-top:-1rem !important;}
#l33t{width:1rem;margin:-1rem 2rem 2rem 6.6rem;}
.midnav{height:2.3rem;font-size:0.7rem;letter-spacing:0.17rem;}
.midp{width:6.2rem;padding:0.55rem 1rem;border-bottom:2px solid #019100;}
.dropdown a{padding:0.55rem 0 0.55rem 0.1rem;border-bottom:2px solid #911000;}
#contactForm, .alert{font-size:0.9rem;}
#introtxt{font-size:0.9rem;}
.blink{width:4px;}
.bottomnav{font-size:1rem;}
.instagram{width:1rem;}
.refind{width:1rem;}
.keybase{width:1.3rem;}
}
@media screen and (max-width:182px){#profile{flex-flow:column wrap;justify-content:center;vertical-align:middle !important;align-items:center;}
#profilebg{height:7rem;}
#dp{width:3.3rem;height:3.3rem;margin:0.8rem 0 -0.5rem 0;}
#info{width:7.5rem;height:3rem;margin:0;}
#info>h1{margin:0.7rem;}
#l33t{width:1rem;margin:-1.3rem 2rem 2rem 6.2rem;}
.midnav{height:2.1rem;font-size:0.6rem;letter-spacing:0.1rem;}
.midp{width:5.2rem;padding:0.55rem 1rem;}
.dropdown a{padding:0.55rem 0 0.55rem 0rem;}
#contactForm, .alert{font-size:0.8rem;letter-spacing:0.5px;}
#introtxt{font-size:0.8rem;letter-spacing:0.5px;}
}