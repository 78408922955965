figure.effect{display:flex;flex-flow:column wrap;justify-content:middle;align-items:center;text-align:center;cursor:pointer;position:relative;overflow:hidden;-webkit-backface-visibility:hidden;backface-visibility:hidden;width:100%;height:35rem;margin:5rem 0;background-color:Black;transition:all 0.5s;-webkit-transition:all 0.5s;}
figure.effect:hover{-webkit-box-shadow:0.5rem 0.5rem 3rem aquamarine;box-shadow:0.5rem 0.5rem 3rem aquamarine;-webkit-transition:0.3s ease-in-out;transition:0.3s ease-in-out;}
figure#red:hover{background-color:rgba(255,0,0,0.8);}
figure#blue:hover{background-color:rgba(0,0,255,0.8);}
figure#green:hover{background-color:rgba(0,255,0,0.8);}
figure#red figcaption{color:rgba(255,0,0,1);}
figure#blue figcaption{color:rgba(0,0,255,1);}
figure#green figcaption{color:rgba(0,255,0,1);}
figure:focus{outline:none;}
figure.effect img{transform:scale(1);width:inherit;height:inherit;object-fit:cover;position:relative;display:block;max-width:100%;transition:all 0.5s;-webkit-transition:all 0.5s;}	
figure.effect:hover img{opacity:0.4;transform:scale(1.08);-webkit-transform:scale(1.08);-webkit-transition:opacity 0.35s, transform 0.5s;transition:opacity 0.35s, transform 0.5s;}
figure.effect figcaption{font-family:'Gugi' cursive;font-weight:bold;color:#f00;margin-top:-35%;font-size:4rem;display:flex;flex-direction:column;align-items:center;justify-content:middle;padding:1rem;-webkit-backface-visibility:hidden;backface-visibility:hidden;width:70%;}
figure.effect figcaption::before,
figure.effect figcaption::after{position:absolute;top:2.5rem;right:2.5rem;bottom:2.5rem;left:2.5rem;content:'';padding-top:5rem;text-transform:uppercase;opacity:0;-webkit-transition:opacity 0.35s, -webkit-transform 0.5s;transition:opacity 0.35s, transform 0.5s;}
figure.effect figcaption::before{border-top:1px solid #fff;border-bottom:1px solid #fff;-webkit-transform:scale(0,1);transform:scale(0,1);}
figure.effect figcaption::after{border-right:1px solid #fff;border-left:1px solid #fff;-webkit-transform:scale(1,0);transform:scale(1,0);}
figure.effect:hover figcaption::before,
figure.effect:hover figcaption::after{color:white;opacity:1;-webkit-transform:scale(1);transform:scale(1);}
figure.effect figcaption::before,figure.effect figcaption::after{content:attr(psuedoattr);}
.oview{font-size:2.5rem;font-weight:normal;margin:6.5% 12% auto 12%;opacity:0;transition:all 0.5s;-webkit-transition:all 0.5s;}
figure.effect:hover p.oview{display:block;color:white;opacity:1;-webkit-transform:translate3d(0,20px,0);transform:translate3d(0,20px,0);-webkit-transition:opacity 0.35s, color 0.5s, -webkit-transform 0.35s;transition:opacity 0.35s, color 0.5s, transform 0.35s;}